import React, { useEffect, useState, useRef } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// HOC for hCaptcha
const withHCaptcha = (WrappedComponent) => {
  const WithHCaptchaComponent = (props) => {
    const { pageContext } = props;
    const hcaptchaRef = useRef(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      setIsReady(true);
    }, []);

    const handleExecuteRecaptcha = async (action) => {
      console.log(`WithCaptcha: handleExecuteRecaptcha captchaProvider=hcaptcha action=${action}`)

      if (!hcaptchaRef.current) {
        throw new Error('hCaptcha has not been initialized');
      }

      try {
        const result = await hcaptchaRef.current.execute({ async: true });
        return result.response;
      } catch (error) {
        console.error('hCaptcha execution failed:', error);
        throw error;
      }
    };

    if (!isReady) {
      return null; // Or a loading component
    }

    return (
      <>
        <HCaptcha
          sitekey={pageContext?.businessAppConfiguration?.hcaptchaKey}
          ref={hcaptchaRef}
          size="invisible"
        />
        <WrappedComponent
          {...props}
          googleReCaptchaProps={{ 
            executeRecaptcha: handleExecuteRecaptcha,
          }}
        />
      </>
    );
  };

  WithHCaptchaComponent.displayName = `WithHCaptcha(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithHCaptchaComponent;
};

// Main HOC that conditionally applies the appropriate captcha HOC
const withCaptcha = (WrappedComponent) => {
  // Create the wrapped components once
  const HCaptchaWrappedComponent = withHCaptcha(WrappedComponent);
  const GoogleCaptchaWrappedComponent = withGoogleReCaptcha(WrappedComponent);

  const WithCaptchaComponent = (props) => {
    const { pageContext } = props;
    const captchaProvider = pageContext?.businessAppConfiguration?.captchaProvider;

    if (captchaProvider === 'hcaptcha') {
      return <HCaptchaWrappedComponent {...props} />;
    }

    return <GoogleCaptchaWrappedComponent {...props} />;
  };

  WithCaptchaComponent.displayName = `WithCaptcha(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithCaptchaComponent;
};

export default withCaptcha;
